.menu{
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
}

.iconRound{
    height: 40px;
    width: 40px;
}


.zIndex-0{
    z-index: -1;
}
.zIndex-1{
    z-index: 99;
}