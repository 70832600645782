.p-bell{
    position: relative;
}

.p-count{
    height: 20px;
    width: 20px;
    position: absolute;
    top: -6px;
    left: -10px;
    
}

.image-body{
    position: relative;
    
}

.dashboard-link{
    position: absolute;
    top: 56px;
    right: -40px;  
    
}

.profile i{
    color: #A8A8A8;
}
.profile p{
    color: #A8A8A8;
}

.notification{
    height: 300px;
    overflow-y:scroll ;
    position: absolute;
    top: 54px;
    right: -119px;
}

.delete{
    height: 20px;
    width: 20px;
    cursor: pointer;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 20px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #E0E0E0;
    border-radius: 20px;
  }
